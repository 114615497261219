import React from 'react';
import Countdown from 'react-countdown';
import { Box, Grid, SxProps } from 'theme-ui';
import { ChildrenProps, Locale, PageMeta } from '@types';
import { Paragraph } from '@themed';
import { useLocale } from '@modules';
import { Logo, logoSourceReplacer } from '../usePressLogos';
import { cmsTextStyles } from './PDFForm.styles';
import { LogoMapper } from './LogoMapper';
import { ContentMapper } from './PDFForm';
import { timerStyles, gridStyles } from './CountdownPanel.styles';

interface TimerNumberInterface {
  time: string;
}

const TimerNumber = ({ time }: TimerNumberInterface) => (
  <>
    {time.split('').map(num => (
      <Paragraph sx={timerStyles.countdownNumber}>
        {num}
      </Paragraph>
    ))}
  </>
);

export interface ContentProps {
  content: PageMeta | undefined;
}

export const CountdownPanel = ({ content }: ContentProps) => (
  <Grid sx={gridStyles}>
    <ContentMapper content={content?.content?.[1]} sx={cmsTextStyles} />
    <Timer />
  </Grid>
);

const logos: Logo[] = [
  {
    name: 'HSLU',
    img: '/images/digital-insurance-experience/hslu.png',
    link: 'https://www.hslu.ch/de-ch/',
    height: '100%',
    width: '100%',
  },
  {
    name: 'Finnoconsult',
    img: '/images/finnoconsult.png',
    link: 'https://finnoconsult.at/',
    height: '100%',
    width: '100%',
  },
  {
    name: 'Adnovum',
    img: '/images/digital-insurance-experience/adnovum.png',
    link: 'https://www.adnovum.com/',
    height: '100%',
    width: '100%',
  },
];

interface rendererProps {
  days: number;
  hours: number;
  minutes:number;
  seconds:number;
}
const format = (number:number) => (number < 10 ? `${0}${number}`: number);

const renderer = ({ days, hours, minutes, seconds }:rendererProps) => (
  <Grid sx={timerStyles.countdown}>
    <Paragraph sx={timerStyles.countdownContent}><TimerNumber time={`${format(days)}`} /></Paragraph>
    <Paragraph sx={timerStyles.countdownContent}><TimerNumber time={`${format(hours)}`} /></Paragraph>
    <Paragraph sx={timerStyles.countdownContent}><TimerNumber time={`${format(minutes)}`} /></Paragraph>
    <Paragraph sx={timerStyles.countdownContent}><TimerNumber time={`${format(seconds)}`} /></Paragraph>
  </Grid>
);

const formatDateTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const extendedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${extendedMinutes}`;
};

const localeFormatter = (date: Date, locale: Locale) => {
  const weekDay = new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  const month = new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);
  const day = new Intl.DateTimeFormat(locale, { day: '2-digit' }).format(date);
  const year = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(date);
  return `${weekDay} ${day}. ${month} ${year}`;
};

const Timer = () => {
  const locale = useLocale();

  const conferenceStart = new Date('2023-11-30T10:00:00');
  const conferenceEnd = new Date('2023-11-30T16:30:00');
  const hasConferenceStarted = (conferenceStart.getTime() - new Date().getTime()) < 0;

  return (hasConferenceStarted ? null : (
    <Grid sx={timerStyles}>

      <Countdown date={conferenceStart} renderer={renderer} />

      <Box>
        <Paragraph sx={timerStyles.date}><b>{localeFormatter(conferenceStart, locale)}</b></Paragraph>
        <Paragraph sx={timerStyles.date}>{`${formatDateTime(conferenceStart)} bis ${formatDateTime(conferenceEnd)} Uhr`}</Paragraph>
      </Box>
      <Grid sx={timerStyles.logos}>
        <LogoMapper logos={logoSourceReplacer(logos)} />
      </Grid>
    </Grid>
  ));
};
export interface GridComponentProps extends ChildrenProps, SxProps{
}
