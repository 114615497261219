import { SxStyleProp } from 'theme-ui';
import { styles } from './PDFForm.styles';

export const gridStyles = {
  ...styles(),
  placeItems: 'inherit',
};

const timeFontStyle = {
  fontSize: ['m', 'l', 'xl', 'xl'],
  fontWeight: '500',
};

export const timerStyles = {
  height: '100%',
  maxHeight: '400px',
  bg: '#F3F4F7',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1.5fr 1fr 1fr',
  placeItems: 'center',
  py: 0,
  px: 7,
  '#timer': {
  },
  logos: {
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    width: '100%',
    gap: 5,
    '.logoMapper-link': {
      height: '100%',
      width: '100%',
    },
    '.press-logo': {
      filter: 'none',
    },
  },
  date: {
    fontSize: ['xs', 's', 's'],
    margin: '0 !important',
    padding: '0 !important',
    textAlign: 'center',
  } as SxStyleProp,
  countdown: {
    display: 'grid',
    gridTemplateColumns: ['repeat(4, max-content)', 'repeat(4, max-content)', 'repeat(2, max-content)', 'repeat(4, max-content)'],
    gridTemplateRows: ['1fr', '1fr', '1fr 1fr', '1fr'],
  },
  countdownContent: {
    ...timeFontStyle,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '&:not(:last-child):after': {
      content: '" : "',
      ml: 3,
    },
  } as SxStyleProp,
  countdownNumber: {
    ...timeFontStyle,
    background: 'linear-gradient(180deg, #fff 50%, #e9e9e9 50%)',
    p: 3,
    py: 2,
    mr: 2,
  },
};
