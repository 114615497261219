/* eslint-disable react/no-children-prop */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CountdownPanel } from 'src/components/composite/digital-experience/CountdownPanel';
import { PDFForm } from 'src/components/composite/digital-experience/PDFForm';
import { DigitalExperience, LocaleGuard } from '@composite';
import { Layout, StaticSEO } from '@core';
import { useStrapiPageContent } from 'src/content/useStrapiPageContent';
import { Init } from 'src/components/composite/digital-experience/Init';

export default () => {
  const pageContent = useStrapiPageContent('digital-experience');

  return (
    <LocaleGuard enabledLocales={['de']} redirectTo="/de/finnoscore/digital-insurance-experience-studie">
      <Layout>
        <StaticSEO pageId="digital-experience" />
        <DigitalExperience pageContent={pageContent}>
          <Init content={pageContent} />
          <PDFForm content={pageContent} />
          <CountdownPanel content={pageContent} />
        </DigitalExperience>
      </Layout>
    </LocaleGuard>
  );
};
